import { Link } from "gatsby"
import React, { useState, useEffect } from "react"

const Navbar = () => {
  const [buttonClicked, setButtonClicked] = useState(true)
  const [width, setWidth] = useState(
    typeof window !== `undefined` ? window.innerWidth : null
  )
  const clickHandler = () => {
    setButtonClicked(!buttonClicked)
  }
  function handleWindowSizeChange() {
    setWidth(typeof window !== `undefined` ? window.innerWidth : null)
  }
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", handleWindowSizeChange)
    }
    if (width <= 768) {
      setButtonClicked(false)
    }
    if (width >= 780) {
      setButtonClicked(true)
    }
  }, [width])

  return (
    <nav className="flex items-center justify-between flex-wrap p-2 lg:mx-10">
      <div className="flex items-center flex-shrink-0 text-blue mr-6">
        <Link to="/">
          <img
            src="/Marsandi-Logo-color.png"
            alt="Marsandi Logo"
            width="200"
            height="auto"
          />
        </Link>
      </div>
      <div className="block lg:hidden">
        <button
          className="flex items-center px-3 py-2 border rounded"
          onClick={() => {
            clickHandler()
          }}
        >
          <svg
            className="fill-current h-6 w-6"
            viewBox="0 0 20 20"
            xmlns="https://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      {buttonClicked && (
        <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
          <div className="text-sm lg:flex-grow"></div>
          <div>
            <Link
              to="/services"
              className="block mt-4 lg:inline-block lg:mt-0 text-customgray hover:text-customblue mr-10"
            >
              Services
            </Link>
            <Link
              to="/work"
              className="block mt-4 lg:inline-block lg:mt-0 text-customgray hover:text-customblue mr-10"
            >
              Work
            </Link>
            <Link
              to="/contact"
              className="inline-block text-md px-4 py-4 leading-none rounded text-white bg-customblue mt-4 lg:mt-0"
            >
              Get In Touch
            </Link>
          </div>
        </div>
      )}
    </nav>
  )
}

export default Navbar
