import React, { useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Banner = () => {
  const [signUp, setSignUp] = useState(false)

  return (
    <div className="py-20 text-white lg:h-4/6 text-center grid justify-items-center align-middle bg-customdarkblue">
      <div className="w-full lg:w-6/12 flex flex-col items-center">
        <h2 className="text-3xl mb-5">Stay Connected</h2>
        <p className="text-md lg:text-xl w-9/12 lg:w-11/12 xl:w-9/12 text-center">
          Receive up to date tips and tricks on managing your business. From
          email design trends to website SEO tips. Keep a look out for free
          resources as well.
        </p>
      </div>
      <div className="my-5 px-2 lg:px-0 w-10/12 md:w-8/12 lg:w-3/12">
        <Formik
          initialValues={{
            email_address: "",
          }}
          onSubmit={(values, actions) => {
            fetch("/", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encode({ "form-name": "signup-form", ...values }),
            })
              .then(() => {
                actions.resetForm()
                setSignUp(true)
                console.log("submitted")
              })
              .catch(() => {
                console.log("error submission")
              })
              .finally(() => actions.setSubmitting(false))
          }}
          validate={values => {
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
            const errors = {}
            if (
              !values.email_address ||
              !emailRegex.test(values.email_address)
            ) {
              errors.email_address = "Valid Email Required"
            }
            return errors
          }}
        >
          <Form name="signup-form" data-netlify={true}>
            <div className="flex items-center bg-white rounded my-2">
              <Field
                type="text"
                name="email_address"
                id="email_address"
                autoComplete="email"
                className="appearance-none bg-transparent border-none text-customdarkblue mr-3 py-1 px-2 leading-tight w-7/12 md:w-8/12 focus:ring-2 focus:ring-customblue focus:border-customblue"
              />
              <ErrorMessage name="email_address" />
              <button
                className="flex-shrink-0 bg-customblue hover:bg-gray-200 text-sm text-white py-3 px-4 rounded w-5/12 md:w-4/12 focus:outline-none"
                type="submit"
              >
                Sign Up
              </button>
            </div>
          </Form>
        </Formik>
        {signUp && <p className="mt-4">Thanks for subscribing!</p>}
      </div>
    </div>
  )
}

export default Banner
