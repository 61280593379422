import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagramSquare,
  faTwitterSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
  return (
    <div className="bg-customdarkblue lg:grid justify-items-center align-middle">
      <div className="flex flex-col items-center text-center md:grid md:grid-cols-4 md:gap-10 lg:px-40 py-10">
        <div className="flex items-center mb-10 md:mb-0">
          <Link to="/" className="text-white">
            <img
              src="/Marsandi-Logo-vert-white.png"
              height="auto"
              width="150"
              alt="Logo"
            />
          </Link>
        </div>
        <div className="leading-loose mb-10 md:mb-0">
          <h4 className="text-lg text-white mb-2">
            <Link to="/" className="text-white">
              Company
            </Link>
          </h4>
          <p>
            <Link to="/services" className="text-white">
              Services
            </Link>
          </p>
          <p>
            <Link to="/work" className="text-white">
              Work
            </Link>
          </p>
          <p>
            <Link to="/contact" className="text-white">
              Contact
            </Link>
          </p>
          <p>
            <Link to="/contact" className="text-white">
              Support
            </Link>
          </p>
        </div>
        <div className="leading-loose mb-10 md:mb-0">
          <h4 className="text-lg text-white mb-2">Services</h4>
          <p>
            <Link to="/services" className="text-white">
              Branding & Logo Design
            </Link>
          </p>
          <p>
            <Link to="/services" className="text-white">
              UI/UX Design
            </Link>
          </p>
          <p>
            <Link to="/services" className="text-white">
              Website Development
            </Link>
          </p>
          <p>
            <Link to="/services" className="text-white">
              Web/Mobile Apps
            </Link>
          </p>
          <p>
            <Link to="/services" className="text-white">
              Social Media Management
            </Link>
          </p>
          <p>
            <Link to="/services" className="text-white">
              Email Marketing Management
            </Link>
          </p>
        </div>
        <div className="flex items-center">
          <div>
            <span className="mr-5">
              <a
                href="https://www.instagram.com/devmarsandi"
                className="text-white"
              >
                <FontAwesomeIcon
                  icon={faInstagramSquare}
                  className="text-white text-4xl"
                />
              </a>
            </span>
            <span className="mr-5">
              <a href="https://twitter.com/devmarsandi" className="text-white">
                <FontAwesomeIcon
                  icon={faTwitterSquare}
                  className="text-white text-4xl"
                />
              </a>
            </span>
            <span>
              <a
                href="https://www.linkedin.com/company/marsandi"
                className="text-white"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="text-white text-4xl"
                />
              </a>
            </span>
          </div>
        </div>
      </div>
      <p className="text-white text-center pb-10">
        Copyright {new Date().getFullYear()} Marsandi LLC
      </p>
    </div>
  )
}

export default Footer
